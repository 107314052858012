<script lang="ts">
  import type {ICompany} from '@jobgether/types';
  import { onMount } from 'svelte';
  import { TinySlider } from "svelte-tiny-slider";

  import CompanyCard from '../company/card.svelte';

  let companiesList = [];
  async function getCompanies(): Promise<ICompany[]> {
    const res = await fetch('/astroapi/home/companies.json');
    const data = await res.json();

    if (res.ok) {
      companiesList = data;
      return data;
    } else {
      console.error('Error fetching companies:',  data);
      return [];
    }
  }

  // #region sliders-logic
  const sliderSettings = {
    gap: '22px',
    fill: true,
    transitionDuration: 300,
    threshold: 30,
  };
  const autoplayDelay = 5000;
  let autoplayInterval: NodeJS.Timeout;
  let setIndex: TinySlider['setIndex'];
  $: currentItem = 0;

  function nextSlide(index: number) {
    return () => setIndex(Math.min(index + 1, companiesList.length - 1));
  }

  function updateCurrentItem(index: number) {
    currentItem = index;
  }

  function autoplay() {
    if (currentItem >= companiesList.length - 4) {
      setIndex(0);
    } else {
      nextSlide(currentItem)();
    }
  }
  // #endregion sliders-logic

  onMount(() => {
    return ;
    autoplayInterval = setInterval(() => {
      autoplay();
    }, autoplayDelay);
    return () => {
      if (autoplayInterval) {
        clearInterval(autoplayInterval);
      }
    }
  });
</script>


{#await getCompanies()}
  Loading...
{:then companiesList}
<section class="hidden md:block bg-blue-app py-16 pl-12 pr-6 -mx-6 mb-16">
  <div class="container mx-auto">
    <div class="flex justify-between items-center mb-16">
      <div>
        <h2 class="text-white text-2xl font-bold mb-4">Our verified employers</h2>
        <p class="text-white text-xl">
          Choose the right fit for your career with verified remote and <br> flexible companies.
        </p>
      </div>
      <img src="/astro/images/icons/VerifiedLogo.svg" alt="Verified Logo" class="w-44 h-44" />
    </div>
  </div>

  <div class="-mx-6">
    <TinySlider
      let:sliderWidth
      {...sliderSettings}
      bind:setIndex
      on:change={(event) => updateCurrentItem(event.detail)}
    >
      {#each companiesList as company, index}
        <div class="flex pr-2 *:grow w-96">
          <CompanyCard {company} />
        </div>
      {/each}
    </TinySlider>
  </div>

  <div class="container mx-auto flex justify-end mt-16">
    <a
      class="px-16 py-3 border-2 border-primary rounded-md text-primary hover:text-white
        bg-white hover:bg-primary transition-colors duration-200 font-semibold
      "
      href="/browse-companies"
    >
      Browse companies
    </a>
  </div>
</section>
{/await}