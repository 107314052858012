<script>
  export let company;
  export let isHomeVerified = false;

  const defaultCompanyLogo = import.meta.env.PUBLIC_DEFAULT_COMPANY_LOGO;
  const siteUrl = import.meta.env.PUBLIC_SITE_URL;

  $: companyBackgroundImage = (company.meta?.background_image?.length && company.meta.background_image?.length > 0 && company.meta.background_image[0]?.url)
    ? company.meta.background_image[0].url
    : defaultCompanyLogo;

  $: companyLogo = (company.meta?.logo?.length && company.meta.logo?.length > 0 && company.meta.logo[0]?.url)
    ? company.meta.logo[0].url
    : defaultCompanyLogo;

  $: industryName = (company.meta.industries && typeof company.meta.industries[0] !== 'string' && company.meta.industries[0]?.name_en)
    ? company.meta.industries[0].name_en
    : '';

  $: flexScore = Math.round(company.meta?.flexScore || 0);
</script>

<div class="relative text-primary h-100 flex overflow-hidden flex-col items-center rounded-lg
    bg-white shadow-lg transition-all duration-200 ease-in-out no-underline hover:shadow-md
    active:shadow-none active:scale-95
  "
>
  <a
    target="_blank"
    href="{siteUrl}/company/{company.meta.slug}/jobs"
    title="See offers from {company.name}"
    aria-label="See the offers from {company.name}"
    class="absolute text-base right-5 bottom-5 font-bold underline"
  >See offers</a>

  <a
    api-template="card"
    target="_blank"
    href="{siteUrl}/company/{company.meta.slug}/jobs"
    class="w-full absolute h-full"
    title="See more about {company.name}"
    aria-label="See more about {company.name}"
  />
  <div
    class="relative flex w-full flex-col items-center border-b border-gray-200 h-[111.15px]"
    class:imgwrap-home={isHomeVerified}
  >
    <img
      class="w-full h-full object-cover"
      loading="lazy"
      src={companyBackgroundImage}
      alt="Company's Banner"
    >
    <div class="absolute searchcard-logowrap left-[10%] w-[5.5em] bottom-0 bg-white shadow-md rounded-t-lg">
      <img
        class="object-contain rounded-t-lg"
        loading="lazy"
        src={companyLogo}
        alt="Company's logo"
      >
      {#if company.status === 3 || company.status === 5}
        <img
          alt="Icon for a company verified by Jobgether"
          src="/astro/images/icons/StampSmall.svg"
          class="absolute -right-3 -top-3"
          width="24"
          height="24"
        />
      {/if}
    </div>
  </div>
  <div class="flex w-full p-4 justify-between items-start flex-col text-base md:text-lg lg:text-xl grow">
    <div class="flex justify-between flex-col w-full h-full">
      <div class="flex justify-between items-start mb-4">
        <span class="font-bold text-xl mb-0 leading-5">
          {company.name}
        </span>
        <div class="flex flex-col gap-3 justify-around text-center font-semibold ">
          <span class="p-big percentage mx-auto mb-0 leading-5 text-2xl">{flexScore}%</span>
          <span class="text-xs font-semibold uppercase mb-0">
            Flex score
          </span>
        </div>
      </div>
      <div class="flex gap-2 company-search mb-4">
        <p class="text-xs  font-medium no-translation industries_text">
          {industryName}
        </p>
      </div>
      <div class="flex gap-2 text-sm justify-start mb-4">
        <p class="flex py-1.5 justify-center items-center rounded-full bg-gradient-to-r from-blue-app to-highlight text-white text-base font-medium whitespace-nowrap text-center px-4">
          {company.meta.remoteOrganizationType}
        </p>
        <p class="flex justify-center items-center border border-solid border-gray-200 rounded-full text-blue-app text-base font-semibold text-center px-4">
          {company.meta.companyType}
        </p>
      </div>
      <div class="flex items-center"
        class:opacity-0={!company.meta.mainOfficeLocation?.name}
      >
        <div class="inline-flex mr-auto">
          <svg class="image-icon" alt="Pin de localisation" width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.99999 0.0077296C5.23672 -0.0757787 3.51098 0.520039 2.19949 1.66515C0.888001 2.81025 0.0972968 4.41156 0 6.11959C0 11.5314 6.99999 19 6.99999 19C6.99999 19 14 11.5314 14 6.11959C13.9027 4.41156 13.112 2.81025 11.8005 1.66515C10.489 0.520039 8.76326 -0.0757787 6.99999 0.0077296ZM6.99999 9.50387C6.25738 9.50387 5.5452 9.21799 5.0201 8.70917C4.49499 8.20035 4.19997 7.51029 4.19997 6.79071C4.19997 6.07113 4.49499 5.38089 5.0201 4.87207C5.5452 4.36325 6.25738 4.07737 6.99999 4.07737C7.7426 4.07737 8.45478 4.36325 8.97989 4.87207C9.50499 5.38089 9.79997 6.07113 9.79997 6.79071C9.79997 7.51029 9.50499 8.20035 8.97989 8.70917C8.45478 9.21799 7.7426 9.50387 6.99999 9.50387V9.50387Z" fill="#6E8D98"/>
          </svg>
          <p class="text-xs font-medium mt-0.5 ml-2 mb-0 location_text w-full">
            {company.meta.mainOfficeLocation?.name}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>